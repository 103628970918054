import {
  Button, MenuItem, Select, Toolbar,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import React from 'react';
import { sanitizeListRestProps, PaginationLimit } from 'react-admin';

interface PaginationActionsProps {
  backIconButtonProps: object,
  count: number,
  nextIconButtonProps: object,
  onChangePage: (e: React.SyntheticEvent, page: number) => void,
  page: number,
  rowsPerPage: number,
}

const CustomActions = ({
  page: pageProp, rowsPerPage, count, onChangePage,
}: PaginationActionsProps) => {
  const totalPages = Math.ceil(count / rowsPerPage);
  const page = (pageProp < 1 || Number.isNaN(pageProp)) ? 1 : pageProp;
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  const handlePrevPage = (e: React.MouseEvent) => {
    if (page >= 1) {
      onChangePage(e, page - 1);
    }
  };

  const handleNextPage = (e: React.MouseEvent) => {
    if (page < totalPages) {
      onChangePage(e, page + 1);
    }
  };

  return (
    <Toolbar style={{
      display: 'flex', justifyContent: 'space-between', padding: '10px 0', minWidth: '200px',
    }}
    >
      <Button
        onClick={handlePrevPage}
        disabled={page <= 1}
      >
        &lt; Prev
      </Button>

      Page:
      <Select
        value={page.toString()}
        style={{ fontWeight: 'bold' }}
        onChange={(e) => onChangePage(e as React.ChangeEvent<HTMLSelectElement>, parseInt(String(e.target.value), 10))}
      >
        {pages.map((value) => (
          <MenuItem selected={page.toString() === value.toString()} key={value} value={value.toString()}>{value}</MenuItem>
        ))}
      </Select>

      <Button
        onClick={handleNextPage}
        disabled={page >= totalPages}
      >
        Next &gt;
      </Button>
    </Toolbar>
  );
};

interface CustomPaginationProps {
    // classes: object,
    // className: string,
    // ids: string[],
    isLoading: boolean,
    page: number,
    perPage: number,
    rowsPerPageOptions: number[],
    setPage: (n: number) => void,
    setPerPage: (n: number) => void,
    translate: (t: string, o?: any)=> string
    total: number,
  }

export const CustomPagination = ({
  isLoading,
  page: pageProp,
  perPage,
  setPage,
  setPerPage,
  rowsPerPageOptions = [10, 25, 50, 100, 150, 200],
  total,
  translate,
  ...rest
}: CustomPaginationProps) => {
  const page = (pageProp < 1 || Number.isNaN(pageProp)) ? 1 : pageProp;

  const magPages = Math.ceil(total / perPage) || 1;

  const handlePageChange = (event: React.SyntheticEvent, page: number) => {
    if (event)event.stopPropagation();
    if (page < 1 || page > magPages) {
      throw new Error(
        translate('ra.navigation.page_out_of_boundaries', {
          page,
        }),
      );
    }
    setPage(page);
  };
  const handlePerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerPage(parseInt(event.target.value, 10));
  };

  const labelDisplayedRows = ({ from, to, count }: { from: number; to: number; count: number }) => translate('ra.navigation.page_range_info', {
    offsetBegin: from,
    offsetEnd: to,
    total: count,
  });

  if (!isLoading && total === 0) {
    return <PaginationLimit />;
  }

  return (
    <TablePagination
      count={total}
      rowsPerPage={perPage}
      page={page}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      ActionsComponent={CustomActions}
      component="span"
      labelRowsPerPage={translate(
        'ra.navigation.page_rows_per_page',
      )}
      labelDisplayedRows={labelDisplayedRows}
      rowsPerPageOptions={rowsPerPageOptions}
      {...sanitizeListRestProps(rest)}
    />
  );
};
