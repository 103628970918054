/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
import {
  List, Datagrid, TextField,
  FunctionField,
} from 'react-admin';
import {
  Company,
  __,
  config,
} from '@adac/core-model';

import CompanyFilter from './CompanyFilter';
import { CompanyDriverListButton } from './CompanyDriverListButton';
import { GenericListActions } from '../common/GenericListActions';
import { NotificationSettingsButton } from './NotificationSettingsButton';
import { EditButtonWithRole } from '../common/react-admin/EditButtonWithRole';
import { CustomPagination } from '../cases/CustomPagination';

const sndCompanyRowStyle = (record: any) => ({
  backgroundColor: record.active ? 'white': '#ffe6e6',
});

const CompanyList = (props: any) => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={<GenericListActions allowCreate={false} />}
    filters={<CompanyFilter />}
    filterDefaultValues={{ active: true }}
    pagination={<CustomPagination {...{} as any} />}
  >
    <Datagrid
      rowStyle={sndCompanyRowStyle}
    >
      <TextField
        source="id"
        label={__('Partner ID')}
        sortable
      />
      <TextField
        source="contactManagerId"
        label={__('Contact Manager ID')}
        sortable
      />
      <TextField
        source="name"
        label={__('Company Name')}
        sortable
      />
      <TextField
        source="typeName"
        label={__('Company type')}
        sortable
      />
      <TextField
        source="dispatcherRole"
        label={__('Rolle')}
        sortable={false}
      />
      <TextField
        source="dispatcherName"
        label={__('Company Boss or dispatcher name')}
        sortable={false}
      />
      <TextField
        source="dispatchTargetAddress"
        label={__('DispatchTarget')}
        sortable={false}
      />
      <NotificationSettingsButton history={props.history} />
      <CompanyDriverListButton history={props.history} />
      <EditButtonWithRole label={__('Edit')} />

      <FunctionField
        label={__('createdAt')}
        render={(record: Company) => config.time(record.createdAt)}
        sortable
      />

      <FunctionField
        label={__('updatedAt')}
        render={(record: Company) => config.time(record.updatedAt)}
        sortable
      />
    </Datagrid>
  </List>
);

export default CompanyList;
